import React, { Suspense, lazy, useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { lazyRetry } from "./utils/lazyRetry";
import { CircularProgress } from "@mui/material";
import NotFound from "./NotFound";
import withChunkErrorHandling from "./utils/withChunkErrorHandling";

const WebinarsDisplayer = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarsDisplayer")))
);
const About = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./About")))
);
const WebinarCategories = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarCategories")))
);
const WebinarCategories2 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarCategories2")))
);
const LandingPage = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./NewLandingPage")))
);
const WebinarHomePage2 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarHomePage2")))
);

const WebinarsUnlimited = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarsUnlimited")))
);
const WebinarsUnlimited2 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarsUnlimited2")))
);
const PplTest = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./AdTest")))
);
const PplDashboard = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PplDashboard")))
);
const Ppl = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Ppl")))
);
const Ppl2 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Ppl2")))
);
const PplFundSuccess = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./pplFundSuccess")))
);

const FaqPresenterContainer = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./FaqPresenterContainer")))
);
const Channels = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Channels")))
);
const Channel = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Channel")))
);
const Terms = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Terms")))
);
const UserAccount = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./UserAccount")))
);
const CustomizeChannel = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CustomizeChannel")))
);
const Library = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Library")))
);
const Recommended = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Recommended")))
);
const Details = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Details")))
);
const Branding = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Branding")))
);
const Promote = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Promote")))
);
const Pricing = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Pricing")))
);
const Recorded = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Recorded")))
);
const LinkHost = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./LinkHost")))
);
const Main = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Main")))
);
const Subscriptions = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Subscriptions")))
);
const HostContent = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./HostContent")))
);
const Analytics = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Analytics")))
);
const PrivateRoutes = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PrivateRoutes")))
);
const ManageSubscriptions = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ManageSubscriptions")))
);
const UnsubscribedPage = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./UnsubscribedPage")))
);
const UnsubscribedPage2 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./UnsubscribedPage2")))
);
const WebinarEditor = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarEditor")))
);
const NoHostInfo = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./NoHostInfo")))
);
const PasswordReset = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PasswordReset")))
);
const ForgotPassword = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ForgotPassword")))
);
const WebinarEvents = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarEvents")))
);
const ArchivedWebinars = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ArchivedWebinars")))
);
const ManageEvent = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ManageEvent")))
);
const CreateNewWebinarEvent = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CreateNewWebinarEvent")))
);
const CreateNewWebinarEventAnon = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CreateNewWebinarEventAnon")))
);
const Summary = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Summary")))
);
const Customize = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Customize")))
);
const GetAttendees = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./GetAttendees")))
);
const GetLeads = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./GetLeads")))
);
const Activity = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Activity")))
);
const General = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./General")))
);
const Share = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Share")))
);
const Register = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Register")))
);
const EmailVerificationConfirmation = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./EmailVerificationConfirmation")))
);
const RegistrationStats = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./RegistrationStats")))
);
const AttendedLineGraph = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./AttendedLineGraph")))
);
const PeopleData = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PeopleData")))
);
const MessageHistory = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./MessageHistory")))
);
const ParticipatedWebinars = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ParticipatedWebinars")))
);
const HlsPlayer = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./HlsPlayer")))
);
const HostedWebinars = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./HostedWebinars")))
);
const Login = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Login")))
);
const UnsubscribedSuccessPage = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./UnsubscribedSuccessPage")))
);
const DummyManageWebinarPage = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./DummyManageWebinarPage")))
);
const CompetitorZoom = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorZoom")))
);
const CompetitorWebex = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorWebex")))
);
const CompetitorGoToWebinar = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorGoToWebinar")))
);
const CompetitorOn24 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorOn24")))
);
const CompetitorAdobeConnect = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorAdobeConnect")))
);
const CompetitorLivestream = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorLivestream")))
);
const CompetitorDemio = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorDemio")))
);
const CompetitorBrightTalk = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorBrightTalk")))
);
const CompetitorCvent = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorCvent")))
);
const CompetitorBlueJeans = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorBlueJeans")))
);
const CompetitorWebinarJam = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorWebinarJam")))
);
const CompetitorWebinarNinja = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorWebinarNinja")))
);
const CompetitorRingCentral = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorRingCentral")))
);
const CompetitorClickMeeting = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorClickMeeting")))
);
const CompetitorHubilo = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorHubilo")))
);
const CompetitorLivestorm = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorLivestorm")))
);
const CompetitorBigMarker = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorBigMarker")))
);
const CompetitorWebinato = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorWebinato")))
);
const CompetitorEasyWebinar = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorEasyWebinar")))
);
const CompetitorLiveWebinar = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorLiveWebinar")))
);
const CompetitorHopinSession = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorHopinSession")))
);
const CompetitorWorkCast = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorWorkCast")))
);
const CompetitorSplash = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorSplash")))
);
const CompetitorKultura = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorKultura")))
);
const CompetitorGoldcast = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CompetitorGoldcast")))
);
const CancelClickMeeting = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelClickMeeting")))
);
const CancelZoom = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelZoom")))
);
const CancelWebex = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelWebex")))
);
const CancelGoToWebinar = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelGoToWebinar")))
);
const CancelOn24 = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelOn24")))
);
const CancelAdobeConnect = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelAdobeConnect")))
);
const CancelBlueJeans = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelBlueJeans")))
);
const CancelDemio = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelDemio")))
);
const CancelWebinarJam = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelWebinarJam")))
);
const CancelHubilo = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelHubilo")))
);
const CancelLivestorm = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelLivestorm")))
);
const CancelBigMarker = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelBigMarker")))
);
const CancelWebinato = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelWebinato")))
);
const CancelEasyWebinar = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelEasyWebinar")))
);
const CancelWebinarNinja = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelWebinarNinja")))
);
const CancelLiveWebinar = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelLiveWebinar")))
);
const CancelHopinSession = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelHopinSession")))
);
const CancelSplashthat = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelSplashthat")))
);
const CancelKultura = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelKultura")))
);
const CancelGoldCast = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelGoldCast")))
);
const CancelBrightTalk = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelBrightTalk")))
);
const CancelWorkCast = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CancelWorkCast")))
);
const CTAStats = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CTAStats")))
);
const PollStats = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PollStats")))
);
const SpeakerSignUp = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./SpeakerSignUp")))
);
const Settings = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Settings")))
);
const UserSettings = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./UserSettings")))
);
const ChannelSettingsContainer = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ChannelSettingsContainer")))
);
const WebinarAssets = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./WebinarAssets")))
);
const Views = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Views")))
);
const PPLStats = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PPLStats")))
);
const CreateMeeting = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./CreateMeeting")))
);
const MeetingLibrary = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./MeetingLibrary")))
);
const ManageMeeting = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ManageMeeting")))
);
const MeetingSummary = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./MeetingSummary")))
);
const KeyTopics = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./KeyTopics")))
);
const ActionItems = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./ActionItems")))
);
const KeyDecisions = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./KeyDecisions")))
);
const Blockers = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./Blockers")))
);
const PplAds = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PplAds")))
);
const PplEmailInfo = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PplEmailInfo")))
);
const PplPublicInfo = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./PplPublicInfo")))
);
const EssentialApps = withChunkErrorHandling(
  lazy(() => lazyRetry(() => import("./EssentialApps")))
);

const AppRoutes = ({
  token,
  setUserSucc,
  logIn,
  promptConsent,
  user,
  reactGA,
  sched,
  hist,
  libraryNotif,
  setLibraryNotif,
  handleCloseCircular,
  handleOpenCircular,
  firebaseLogin,
  openLogin,
  firebaseSignup,
  handleCloseLogin,
  firebase2,
  handleLoginOrSignup,
  setDisplayNav,
}) => {
  // const [redirectToHome, setRedirectToHome] = useState(false);

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  // useEffect(() => {
  //   const currentHash = window.location.hash;
  //   if (currentHash && currentHash.length > 0) {
  //     setRedirectToHome(true);
  //   }
  // }, []);

  // if (redirectToHome) {
  //   return <Navigate to="/" />;
  // }
  return (
    <Routes>
      <Route
        path="signup"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <SpeakerSignUp
              // firebaseSignup={firebaseSignup}
              handleCloseLogin={handleCloseLogin}
              logIn={logIn}
              handleLoginOrSignup={handleLoginOrSignup}
            />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-zoom"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorZoom />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-webex"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorWebex />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-gotowebinar"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorGoToWebinar />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-on24"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorOn24 />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-adobeconnect"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorAdobeConnect />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-livestream"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorLivestream />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-demio"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorDemio />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-brighttalk"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorBrightTalk />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-cvent"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorCvent />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-bluejeans"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorBlueJeans />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-webinarjam"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorWebinarJam />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-webinarninja"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorWebinarNinja />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-ringcentral"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorRingCentral />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-clickMeeting"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorClickMeeting />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-hubilo"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorHubilo />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-livestorm"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorLivestorm />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-bigmarker"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorBigMarker />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-webinato"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorWebinato />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-easywebinar"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorEasyWebinar />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-livewebinar"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorLiveWebinar />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-hopinsession"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorHopinSession />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-workcast"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorWorkCast />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-splash"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorSplash />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-kultura"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorKultura />
          </Suspense>
        }
      />
      <Route
        path="free-alternative-to-goldcast"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CompetitorGoldcast />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-clickmeeting"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelClickMeeting />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-zoom"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelZoom />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-webex"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelWebex />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-gotowebinar"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelGoToWebinar />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-on24"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelOn24 />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-adobeconnect"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelAdobeConnect />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-bluejeans"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelBlueJeans />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-demio"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelDemio />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-webinarjam"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelWebinarJam />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-hubilo"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelHubilo />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-livestorm"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelLivestorm />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-bigmarker"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelBigMarker />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-webinato"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelWebinato />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-easywebinar"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelEasyWebinar />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-webinarninja"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelWebinarNinja />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-livewebinar"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelLiveWebinar />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-hopinsession"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelHopinSession />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-splashthat"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelSplashthat />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-kultura"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelKultura />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-goldcast"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelGoldCast />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-brighttalk"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelBrightTalk />
          </Suspense>
        }
      />
      <Route
        path="how-to-cancel-workcast"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CancelWorkCast />
          </Suspense>
        }
      />
      <Route
        path="/"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <LandingPage
              home={true}
              logIn={logIn}
              promptConsent={promptConsent}
              token={token}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />
      <Route
        path="/webinarTV"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <WebinarHomePage2
              home={true}
              logIn={logIn}
              promptConsent={promptConsent}
              token={token}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />

      <Route
        path="login"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Login />
          </Suspense>
        }
      />
      <Route
        path="WU"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <WebinarsUnlimited
              user={user}
              token={token}
              setDisplayNav={setDisplayNav}
            />
          </Suspense>
        }
      />
      <Route
        path="Wtest"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <WebinarsUnlimited2 user={user} token={token} />
          </Suspense>
        }
      />
      <Route
        path="pplTest"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplTest />
          </Suspense>
        }
      />
      <Route
        path="PplDashboard"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplDashboard />
          </Suspense>
        }
      />

      <Route
        path="pplCredits"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplEmailInfo />
          </Suspense>
        }
      />
      <Route
        path="pplInfo"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplPublicInfo />
          </Suspense>
        }
      />
      {/*}
      <Route
        path="ppl/100free"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Ppl2 token={token} />
          </Suspense>
        }
      />
      <Route
        path="ppl/exitfive"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Ppl2 token={token} />
          </Suspense>
        }
      />
      */}
      <Route
        path="PplFundSuccess"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplFundSuccess token={token} />
          </Suspense>
        }
      />
      <Route
        path="ppl"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Ppl token={token} />
          </Suspense>
        }
      />
      <Route
        path="addFunds"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Ppl token={token} />
          </Suspense>
        }
      />

      <Route
        path="join"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <WebinarsUnlimited
              user={user}
              token={token}
              setDisplayNav={setDisplayNav}
            />
          </Suspense>
        }
      />
      <Route
        path="turtle"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <LandingPage
              home={true}
              logIn={logIn}
              promptConsent={promptConsent}
              token={token}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />
      <Route
        path="pricing"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Pricing
              home={true}
              logIn={logIn}
              promptConsent={promptConsent}
              token={token}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />
      <Route
        path="register/:id"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Register logIn={logIn} />
          </Suspense>
        }
      />
      <Route
        path="register/:id/*"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Register logIn={logIn} />
          </Suspense>
        }
      />
      <Route
        path="register/fb/:id"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Register logIn={logIn} />
          </Suspense>
        }
      />
      <Route
        path="register/x/:id"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Register logIn={logIn} />
          </Suspense>
        }
      />
      <Route
        path="register/li/:id"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Register logIn={logIn} />
          </Suspense>
        }
      />
      <Route
        path="ws/categories1"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <WebinarCategories
              home={false}
              logIn={logIn}
              promptConsent={promptConsent}
              token={token}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />
      <Route
        path="catalog"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplAds />
          </Suspense>
        }
      />
      <Route
        path="catalog/:category"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PplAds />
          </Suspense>
        }
      >
        <Route
          path=":subcategory"
          element={
            <Suspense
              fallback={
                <div>
                  <CircularProgress />
                </div>
              }
            >
              <PplAds />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/library"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Library openLogin={openLogin} />
          </Suspense>
        }
      />
      <Route
        path="ws/password-reset"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <PasswordReset firebaseLogin={firebaseLogin} />
          </Suspense>
        }
      />
      <Route
        path="ws/email-verification"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <EmailVerificationConfirmation
              firebase2={firebase2}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />
      <Route
        path="ws/recommended"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Recommended logIn={logIn} promptConsent={promptConsent} />
          </Suspense>
        }
      />
      {/* <Route
        path="ws/subscriptions"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Subscriptions />
          </Suspense>
        }
      />
      <Route
        path="ws/subscriptions/manage"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <ManageSubscriptions />
          </Suspense>
        }
      /> */}
      {/* </Route> */}
      <Route
        path="/create-event/*"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CreateNewWebinarEventAnon user={user} />
          </Suspense>
        }
      />
      <Route
        path="ws/account/create-event"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <CreateNewWebinarEventAnon user={user} />
          </Suspense>
        }
      />
      <Route
        path="ws/account/manage-event"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <DummyManageWebinarPage />
          </Suspense>
        }
      />
      <Route
        element={
          // isLoggedIn ?
          <PrivateRoutes user={user} isLoggedIn={isLoggedIn} />
          // :
          // <Navigate to="/" />
        }
      >
        <Route
          path="ws/account/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <CircularProgress />
                </div>
              }
            >
              <UserAccount />
            </Suspense>
          }
        >
          <Route
            path="create-meeting"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <CreateMeeting />
              </Suspense>
            }
          />
          <Route
            path="customize"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <CustomizeChannel />
              </Suspense>
            }
          >
            <Route
              path="details/claimed"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Details
                    logIn={logIn}
                    promptConsent={promptConsent}
                    user={user}
                    token={token}
                  />
                </Suspense>
              }
            />
            <Route
              path="details"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Details
                    logIn={logIn}
                    promptConsent={promptConsent}
                    user={user}
                    token={token}
                  />
                </Suspense>
              }
            />
            <Route
              path="branding"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Branding />
                </Suspense>
              }
            />
            <Route
              path="promote"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Promote />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="content"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <HostContent />
              </Suspense>
            }
          />
          <Route
            path="hosted"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <HostedWebinars />
              </Suspense>
            }
          />
          <Route
            path="archived"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <ArchivedWebinars />
              </Suspense>
            }
          />
          <Route
            path="viewer-history"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <ParticipatedWebinars />
              </Suspense>
            }
          />
          <Route
            path="player/:wid"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <HlsPlayer logIn={logIn} />
              </Suspense>
            }
          />
          <Route
            path="meetings"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <MeetingLibrary logIn={logIn} />
              </Suspense>
            }
          />
          <Route
            path="manage-event"
            element={<Navigate to="/not-found" replace />}
          />
          <Route
            path="manage-event/:wid"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <ManageEvent />
              </Suspense>
            }
          >
            <Route
              path="summary"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Summary />
                </Suspense>
              }
            />
            <Route
              path="customize"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Customize />
                </Suspense>
              }
            />
            <Route
              path="invite"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <GetAttendees user={user} token={token} />
                </Suspense>
              }
            />
            <Route
              path="get-attendees"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <GetAttendees user={user} token={token} />
                </Suspense>
              }
            />
            <Route
              path="getleads"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <GetLeads user={user} token={token} />
                </Suspense>
              }
            />
            <Route
              path="analytics"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Activity />
                </Suspense>
              }
            >
              <Route
                path="registered"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <RegistrationStats />
                  </Suspense>
                }
              />
              <Route
                path="attended"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <AttendedLineGraph />
                  </Suspense>
                }
              />
              <Route
                path="people"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <PeopleData />
                  </Suspense>
                }
              />
              <Route
                path="views"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Views />
                  </Suspense>
                }
              />
              <Route
                path="messages"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <MessageHistory />
                  </Suspense>
                }
              />
              <Route
                path="cta"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <CTAStats />
                  </Suspense>
                }
              />
              <Route
                path="poll-stats"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <PollStats />
                  </Suspense>
                }
              />
              <Route
                path="ppl-stats"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <CircularProgress />
                      </div>
                    }
                  >
                    <PPLStats />
                  </Suspense>
                }
              />
            </Route>
            {/* <Route path="/ws/account/:id/manage-event" element={<Navigate to={`./summary`} replace />} /> */}
            <Route
              path="/ws/account/:id/manage-event/:wid"
              element={<Navigate to={`../../../not-found`} replace />}
            />
          </Route>
          <Route
            path="meeting-event/:wid"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <ManageMeeting />
              </Suspense>
            }
          >
            <Route
              path="summary"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <MeetingSummary />
                </Suspense>
              }
            />
            <Route
              path="key-topics"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <KeyTopics />
                </Suspense>
              }
            />
            <Route
              path="action-items"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <ActionItems />
                </Suspense>
              }
            />
            <Route
              path="key-decisions"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <KeyDecisions />
                </Suspense>
              }
            />
            <Route
              path="blockers"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <Blockers />
                </Suspense>
              }
            />
            {/* <Route path="/ws/account/:id/manage-event" element={<Navigate to={`./summary`} replace />} /> */}
            <Route
              path="/ws/account/:id/meeting-event/:wid"
              element={<Navigate to={`../../../not-found`} replace />}
            />
          </Route>
          {/* <Route
            path="create-meeting"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <CreateMeeting />
              </Suspense>
            }
          /> */}
          <Route
            path="create-event"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <CreateNewWebinarEvent />
              </Suspense>
            }
          />
          <Route
            path="/ws/account/:id"
            element={<Navigate to={`../../../not-found`} replace />}
          />
        </Route>
        <Route
          path="analytics"
          element={
            <Suspense
              fallback={
                <div>
                  <CircularProgress />
                </div>
              }
            >
              <Analytics />
            </Suspense>
          }
        />
        <Route
          path="settings"
          element={
            <Suspense
              fallback={
                <div>
                  <CircularProgress />
                </div>
              }
            >
              <Settings />
            </Suspense>
          }
        >
          <Route
            path="user"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <UserSettings />
              </Suspense>
            }
          />
          <Route
            path="channel"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <ChannelSettingsContainer />
              </Suspense>
            }
          />
          <Route
            path="webinar-graphics"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <WebinarAssets />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="ws/account/nohost"
          element={
            <Suspense
              fallback={
                <div>
                  <CircularProgress />
                </div>
              }
            >
              <UserAccount />
            </Suspense>
          }
        >
          <Route
            path="customize"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <NoHostInfo />
              </Suspense>
            }
          >
            <Route
              path="details/claimed"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <NoHostInfo />
                </Suspense>
              }
            />
            <Route
              path="details"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <NoHostInfo />
                </Suspense>
              }
            />
            <Route
              path="branding"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <NoHostInfo />
                </Suspense>
              }
            />
            <Route
              path="promote"
              element={
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <NoHostInfo />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="content"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <NoHostInfo />
              </Suspense>
            }
          />
          <Route
            path="analytics"
            element={
              <Suspense
                fallback={
                  <div>
                    <CircularProgress />
                  </div>
                }
              >
                <NoHostInfo />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path="ws/account/about"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <About />
          </Suspense>
        }
      />
      <Route
        path="ws/account/faq"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <FaqPresenterContainer />
          </Suspense>
        }
      />
      <Route
        path="ws/termsofservice"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Terms />
          </Suspense>
        }
      />
      <Route
        path="ws/recorded"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Recorded />
          </Suspense>
        }
      />
      <Route
        path="ws/settings"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Pricing
              user={user}
              token={token}
              logIn={logIn}
              promptConsent={promptConsent}
            />
          </Suspense>
        }
      />
      <Route
        path="ws/password-reset-request"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <ForgotPassword />
          </Suspense>
        }
      />

      <Route
        path="pr"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Pricing
              user={user}
              token={token}
              logIn={logIn}
              promptConsent={promptConsent}
            />
          </Suspense>
        }
      />
      <Route
        path="pricing"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Pricing
              user={user}
              token={token}
              logIn={logIn}
              promptConsent={promptConsent}
            />
          </Suspense>
        }
      />
      <Route
        path="chart"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <div />
          </Suspense>
        }
      />
      <Route
        path="/lk/:code"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <LinkHost
              token={token}
              logIn={logIn}
              user={user}
              promptConsent={promptConsent}
              reactGA={reactGA}
              setUserSucc={setUserSucc}
              handleCloseCircular={handleCloseCircular}
              firebaseLogin={firebaseLogin}
              handleOpenCircular={handleOpenCircular}
            />
          </Suspense>
        }
      />
      <Route
        path="/sadpuppy/:host"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <UnsubscribedPage />
          </Suspense>
        }
      />
      <Route
        path="/unsubscribe/:host"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <UnsubscribedPage2 />
          </Suspense>
        }
      />
      <Route
        path="/stop-overpaying-for-webinar-software"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <UnsubscribedSuccessPage />
          </Suspense>
        }
      />
      <Route
        path="/EssentialApps"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <EssentialApps />
          </Suspense>
        }
      />

      <Route
        path=":id"
        element={
          <Suspense fallback={<div>{/* <CircularProgress /> */}</div>}>
            <Channel
              token={token}
              logIn={logIn}
              promptConsent={promptConsent}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
              openLogin={openLogin}
            />
          </Suspense>
        }
      />
      <Route
        path=":id/:wid"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <Channel
              token={token}
              logIn={logIn}
              promptConsent={promptConsent}
              user={user}
              hist={hist}
              sched={sched}
              libraryNotif={libraryNotif}
              setLibraryNotif={setLibraryNotif}
              reactGA={reactGA}
            />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense
            fallback={
              <div>
                <CircularProgress />
              </div>
            }
          >
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
